import React from "react"
import { useTranslation } from "react-i18next"
import SEO from "../components/seo"

const Page = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO />

      <section className="hero is-medium has-background-dark">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h2 className="subtitle is-size-3 has-text-white">
              {t("Using i18next")}
            </h2>
          </div>
        </div>
      </section>
    </>
  )
}

export default Page
